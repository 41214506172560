import './Home.css';
import { Container } from 'reactstrap';
// import { scrollToContentById } from '../utils';
// import { BatteryCharging, BookOpen, Eye, HelpCircle, Power, Users } from 'react-feather';

function Home() {

  return <div className="page home">
    <div className="full-height landing">
      <div className="content">
        {/* <img src={logo} style={{width: '180px'}} className="App-logo" alt="logo" /> */}
        <div className='brand container'>
          <img alt="Wingpar logo" src="/images/wingspar-logo-lightbg.svg" />
        </div>
        <div className="slogan">Smart platforms and technology solutions to elevate your business</div>
        <Container>Coming soon</Container>
        {/* <Container>
          <Button onClick={() => scrollToContentById('services')} color="primary">Explore our services</Button>
        </Container> */}
      </div>
    </div>

    {/* <div className="full-height top-content" id="services">
      <h1>Welcome to Wingspar</h1>

      <Container className='definition'>
          An aircraft's wing spar is the primary structural component of the wing.<br />
          Constructed of advanced materials it is designed to withstand<br />all loads and stresses experienced.
      </Container>

      <Container className="services">
          <Button onClick={() => scrollToContentById('advisory')} color="primary"><HelpCircle /> Advisory Services</Button>
          <Button onClick={() => scrollToContentById('innovation')} color="primary"><BatteryCharging /> Innovation Incubator</Button>
          <Button onClick={() => scrollToContentById('openbanking')} color="primary"><BookOpen/> Open Banking</Button>
          <Button onClick={() => scrollToContentById('focus')} color="primary"><Eye /> Focus Productivity</Button>
          <Button onClick={() => scrollToContentById('devex')} color="primary"><Users /> Developer Experience</Button>
        </Container>

      <p>
        <strong>wingspar</strong> supports businesses in their lofty aspirations
      </p>

      <p>We:
        <ul>
          <li>Incubate innovative ideas, providing support and funding.</li>
          <li>Provide a development platform to accelerate new ideas getting to market.</li>
          <li>Build software to make your life easier and more productive.</li>
        </ul>    
      </p>
      <Button onClick={() => scrollToContentById('focus-content')} color="link">Remove distractions with Focus</Button>
    </div> 

    <div className="full-height hero focus-content" id="focus">
      <div className="content">
        <h1>Focus</h1>
        <p>We build software to make your life easier and more productive.</p>
        <Button onClick={() => scrollToContentById('top-content')} color="primary">Learn more</Button>
      </div>
    </div> */}

    <Container fluid className="image-attrib">
      Images on this site include amazing photography by <a href="https://unsplash.com/@rparmly?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Ross Parmly</a> 
    </Container>

  </div>;
}

export default Home;
